import React, { useEffect } from 'react'

import { navigate, type PageProps } from 'gatsby'
import { isBrowser } from '@wicadu/arepa/utils'

const Redirect: React.FC<PageProps> = () => {
  useEffect(() => {
    const defaultLang: string = 'en'

    const navigatorLang: string = isBrowser() ? navigator.language : defaultLang
    const lang: string = navigatorLang.startsWith('es') ? 'es' : defaultLang
    navigate(`/${lang}`, { replace: true })
  }, [])

  return null
}

export default Redirect
